@media only screen and (max-width: 1080px) {
.textContact {
    text-align: right;
}
.mwdesign {
    text-align: right;
}
}
@media only screen and (max-width: 900px) {
    .textContact {
        text-align: center;
    }
    .mwdesign {
        text-align: center;
        margin-right: auto;
    }
}

    .textContact {
        text-align: center;
    }
    .mwdesign {
        color: white;
        text-align: center;
        margin-right: auto;
        font-size: 12px;
        font-family:'Montserrat', sans-serif;
        padding: 4px;
        
    }

