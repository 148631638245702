.greenBox1{
    background-color: rgba(140,197,64, 0.6);;
    padding: 5px;
    height: 90px;
    text-align: center;
    display: flex;
    color: white;
    align-items:center;
    justify-content:center;
    font-size: 11px;
    font-family: 'Montserrat', sans-serif;
    backdrop-filter: blur(3px);
}
.greenBox2{
    background-color: rgba(140,197,64, 0.6);;
    padding: 5px;
    height: 90px;
    text-align: center;
    display: flex;
    color: white;
    align-items:center;
    justify-content:center;
    font-size: 11px;
    font-family: 'Montserrat', sans-serif;
    backdrop-filter: blur(3px);
}
.greyBox{
    background-color: rgba(2, 0, 0, 0.6);
    padding: 5px;
    height: 90px;
    text-align: center;
    display: flex;
    color: white;
    align-items:center;
    justify-content:center;
    font-size: 11px;
    font-family: 'Montserrat', sans-serif;
    backdrop-filter: blur(3px);
}
.topBoxContainer {
    max-width: 970px;
    margin-left: auto;
    margin-right: auto;
    margin-top: -104px;
}
.imagesCarousel {
    height: 300px;
    width: 100%;
}

@media only screen and (min-width: 2160px) {
    .imagesCarousel {
        height: 1200px;
    }
    .greyBox {
        height: 200px;
        font-size: 20px;
    }
    .greenBox1 {
        height: 200px;
        font-size: 20px;
    }
    .greenBox2 {
        height: 200px;
        font-size: 20px;
    }
    .topBoxContainer {
        margin-top: -213px;
    }
}
@media only screen and (max-width: 1920px) {
    .imagesCarousel {
        height: 840px;
    }
    .greyBox {
        height: 200px;
        font-size: 20px;
    }
    .greenBox1 {
        height: 200px;
        font-size: 20px;
    }
    .greenBox2 {
        height: 200px;
        font-size: 20px;
    }
    .topBoxContainer {
        margin-top: -213px;
    }
}
@media only screen and (max-width: 1440px) {
    .imagesCarousel {
        height: 680px;
    }
    .greyBox {
        height: 200px;
        font-size: 20px;
    }
    .greenBox1 {
        height: 200px;
        font-size: 20px;
    }
    .greenBox2 {
        height: 200px;
        font-size: 20px;
    }
    .topBoxContainer {
        margin-top: -213px;
    }
}

@media only screen and (max-width: 768px) {

    .imagesCarousel {
    height: 640px;
    width: 100%;
}
    .greenBox1 {
    height: 100px;
    font-size: 17px;
}
    .greenBox2 {
        height: 100px;
        font-size: 17px;
    }
    .greyBox {
    height: 100px;
    font-size: 17px;
}
    .topBoxContainer {
    margin-top: -113px;
}


}
@media only screen and (max-width: 550px) {

    .imagesCarousel {
        height: 300px;
    }
    .greenBox1 {
         height: 90px;
         font-size: 11px;
     }
    .greenBox2 {
        height: 90px;
        font-size: 11px;
    }
    .greyBox {
        height: 90px;
        font-size: 11px;
    }
    .topBoxContainer {
        margin-top: -104px;
    }


}