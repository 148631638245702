.topTextInService {
    text-align: center;
    color: #fff;
    padding: 5px;
}
.services {
    background-image: linear-gradient(rgba(63, 63, 63, 0.7), rgba(63, 63, 63, 0.63)), url("../../images/architect-1080592.jpg");
    background-attachment:scroll;
    background-position:right;
    background-repeat: no-repeat;
    background-size: cover;
    margin-top: 50px;
    min-height: 650px;
    padding-bottom: 20px;
}
.servicesBoxes {
    background-color: rgba(2, 0, 0, 0.3);
    text-align: center;
    display: flex;
    color: white;
    align-items:center;
    justify-content:center;
    padding: 2px;
    margin: 3px;
    height: 140px;
    width: auto;
    border-radius: 30px 0 30px 0;
}
@media only screen and (min-width: 2160px) {
    .services{
        min-height: none;
        background-position: center;
        background-attachment:fixed;
    }
    .servicesBoxes {
        margin: 15px;
        padding: 5px;
        height: 210px;
        width: 500px;
    }
}

@media only screen and (min-width: 1080px) {
    .servicesBoxes{
        width: 350px;
        height: 170px;
        margin-left: auto;
        margin-right: auto;
    }
    .services {
        min-height: none;
        background-position: center;
        background-attachment:fixed;
    }
}
@media only screen and (max-width: 750px) {

    .services {
        min-height: 650px;
        background-position: right;
    }


    .servicesBoxes {
        margin: 3px;
        padding: 2px;
        height: 140px;
        width: auto;
    }


}

@media only screen and (max-width: 450px) {

    .services {
        min-height: 650px;
        background-position: right;
        padding-bottom: 20px;
    }


    .servicesBoxes {
        margin: 3px;
        padding: 2px;
        height: 120px;
        width: auto;
    }


}